import React from "react";
import styles from "./index.module.scss";
import classNames from "classnames";

type Props = {
  className?: string;
  label?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;
export const Input = ({ className, label, ...rest }: Props) => {
  return (
    <div className={styles.container}>
      {label && <p className={styles.label}>{label}:</p>}
      <input className={classNames([ styles.input, className ])} {...rest} />
    </div>
  );
};
