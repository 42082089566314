import React from "react";
import classNames from "classnames";
import styles from "./index.module.scss";
import { ReactSVG } from "react-svg";
import close from "../../assets/img/close.svg";

type Props = {
  showModal: boolean;
  onClose?: () => void;
  width?: number;
  height?: number;
  className?: string;
};

export const Modal = ({
  children,
  showModal,
  onClose,
  width,
  height,
  className,
}: React.PropsWithChildren<Props>) => {
  const noop: React.EventHandler<React.MouseEvent> = (e) => {
    e.stopPropagation();
  };

  return (
    <div>
      {showModal && (
        <div className={styles.modal}>
          <div
            className={classNames(styles.popup, className)}
            onClick={noop}
            style={{ width, height }}
          >
            {onClose && (
              <ReactSVG
                src={close}
                className={styles.close}
                onClick={onClose}
              />
            )}
            {children}
          </div>
        </div>
      )}
    </div>
  );
};
