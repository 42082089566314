import React, {
  createContext,
  useMemo,
  PropsWithChildren,
} from "react";
import { AssetClass, useSdkContext } from "./SdkContext";

type TestCollectionParams = {
  cs: string;
  name: string;
  apy: number;
  price: number;
  deadline: number;
  prefixLen: number;
}

interface AdminContextType {
  addTestCollection: (params: TestCollectionParams) => Promise<AssetClass>,
}

export const AdminContext = createContext<AdminContextType>({
  addTestCollection: async (params) => {
    throw new Error("AdminContext not initialized");
  },
});

// const addTestCollection = async (params: TestCollectionParams) => {
//   const sdk = await getSdk();
//   const assetClass = await sdk.makeTestColl(
//     params.cs,
//     params.name,
//     params.apy,
//     params.price,
//     params.deadline,
//     params.prefixLen
//   );
//   return assetClass;
// };

export const AdminProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { getSdk } = useSdkContext();

  const addTestCollection = async (params: TestCollectionParams) => {
    const sdk = await getSdk();
    const assetClass = await sdk.makeTestColl(
      params.cs,
      params.name,
      params.apy,
      params.price,
      params.deadline,
      params.prefixLen
    );
    return assetClass;
  };

  const contextValue = useMemo(
    () => ({
      addTestCollection
    }),
    [addTestCollection]
  );

  return (
    <AdminContext.Provider value={contextValue}>{children}</AdminContext.Provider>
  );
};

export const useAdminContext = () => React.useContext(AdminContext);
