import { Blockfrost, Lucid, } from "lucid-cardano";
import { mkPartialTxInterpreter, } from "lucid-cardano-partialtx";
const fromHex = (hex) => {
    var res = "";
    for (var i = 0; i < hex.length; i += 2)
        res += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    return res;
};
const mkTokenName = (prefix, counter) => {
    const maxLen = 32;
    const padLeft = (x) => x.padStart(maxLen - prefix.length, "0");
    const str = prefix + padLeft(counter.toString());
    return str;
};
export class Kwarxs {
    constructor() { }
    /** Construct new Kwarxs dapp interface
     * @param bfUrl URL to Blockfrost API
     * @param bfProjectId Blockfrost project ID
     * @param partialTxHost URL to partial-tx server
     * @param network Network to connect to
     * @returns Kwarxs interface
     */
    static async new(bfUrl, bfProjectId, partialTxHost, network) {
        const kwarxs = new this();
        const blockfrostInst = new Blockfrost(bfUrl, bfProjectId);
        const lucid = await Lucid.new(blockfrostInst, network);
        kwarxs.lucid = lucid;
        kwarxs.bfUrl = bfUrl;
        kwarxs.bfProjectId = bfProjectId;
        kwarxs.partialTxHost = partialTxHost;
        kwarxs.connectedWallet = null;
        kwarxs.buildTxFrom = mkPartialTxInterpreter(lucid);
        return kwarxs;
    }
    /** Checks if user connected on of the supported wallets
     *  @returns true if user is connected
     */
    isConnectedToWallet() {
        return this.connectedWallet !== null;
    }
    /** Disconnect user's wallet
     */
    disconnectWallet() {
        this.connectedWallet = null;
    }
    /** Generate object to be used as headers for partial-tx server request,
     *  contains pkh and skh of the user
     *  @returns Header object
     */
    getPartialTxReqHeaders() {
        this.assertWalletConnected();
        return {
            OwnPubKeyHash: this.connectedWallet.pkh,
            "Content-Type": "application/json",
            ...(this.connectedWallet.skh
                ? { OwnStakePubKeyHash: this.connectedWallet.skh }
                : {}),
        };
    }
    /** Generate object to be used as headers for partial-tx server request,
     *  containing empty pkh and skh. Should be used for lookups, like NFT
     *  marketplace, only
     *  @returns Header object
     */
    getDummyPartialTxReqHeaders() {
        return {
            OwnPubKeyHash: "00000000000000000000000000000000000000000000000000000000",
            OwnStakePubKeyHash: "00000000000000000000000000000000000000000000000000000000",
            "Content-Type": "application/json",
        };
    }
    /** Connects user's Nami wallet to the dapp
     */
    async connectToNamiWallet() {
        this.connectToWallet("Nami");
    }
    /** Connects user's Eternl wallet to the dapp
     */
    async connectToEternlWallet() {
        this.connectToWallet("Eternl");
    }
    /** Connects to specified user wallet to the dapp
     */
    async connectToWallet(walletType) {
        let propName;
        if (walletType == "Nami") {
            propName = "nami";
        }
        else if (walletType == "Eternl") {
            propName = "eternl";
        }
        else {
            throw {
                code: "UnsupportedWallet",
                msg: `Desired wallet (${walletType}) is currently unsupported`,
            };
        }
        const wallet = await window.cardano[propName].enable();
        console.log(wallet);
        await this.lucid.selectWallet(wallet);
        const ownWalletAddress = await this.lucid.wallet.address();
        const addressDetails = this.lucid.utils.getAddressDetails(ownWalletAddress);
        this.connectedWallet = {
            walletType: walletType,
            pkh: addressDetails.paymentCredential.hash,
            skh: addressDetails.stakeCredential?.hash,
            bech32: addressDetails.address.bech32,
        };
    }
    // TODO: Connect to different wallets
    /** Get list of NFTs
     * @param getNfts Request, possibly including pagination
     * @returns list of NFTs, contains query to get next page
     */
    async getNftsFromMarketplace(getNfts) {
        const res = await fetch(`${this.partialTxHost}/contracts/nfts`, {
            method: "POST",
            headers: this.getDummyPartialTxReqHeaders(),
            body: JSON.stringify(getNfts),
        }).then((res) => res.json());
        return res;
    }
    /** Construct and submit a transaction minting and creating sell order
     * @param sellNft List of NFTs to mint and sell
     * NOTE: Development use only
     */
    async sellNft(sellNft) {
        console.log("Sell NFT");
        const partialTx = await this.fetchTx("contracts/dev/sell-nft", JSON.stringify(sellNft));
        const curr = Object.keys(partialTx.mint)[0].substring(0, 56);
        let tx = this.buildTxFrom(partialTx);
        let metadata = {
            [curr]: {},
        };
        for (const token of sellNft.sellTokens) {
            const tn = mkTokenName(token.sellPrefix, token.sellCounter);
            // @ts-ignore: TS7053
            metadata[curr][tn] = token.metadata;
        }
        tx = tx.attachMetadata(721, metadata);
        return this.submitTx(tx);
    }
    /** Construct and submit a transaction buying an NFT from marketplace.
     * @param nft NFT to buy, can be constructed from the result of `getNfts`
     * @returns Hash of submitted transaction
     */
    async buyNft(nft) {
        console.log("Buy NFT");
        const serverResponse = await this.fetchTx("nft/buy-nft", JSON.stringify(nft));
        const partialTx = serverResponse.tx;
        // From: https://cips.cardano.org/cips/cip25/
        const nftMetadataKey = 721;
        console.log("partialTx", partialTx);
        const built = this.buildTxFrom(partialTx);
        console.log("built", built);
        const completedTx = await built
            .attachMetadata(nftMetadataKey, serverResponse.metadata[nftMetadataKey])
            .complete();
        console.log("completedTx", completedTx);
        const signedTx = await completedTx
            .sign()
            .complete()
            .catch((e) => {
            if (e.code && e.code === 2)
                throw {
                    code: "UserCancelled",
                    msg: e.info,
                };
            throw {
                code: "OtherFailure",
                msg: JSON.stringify(e),
            };
        });
        console.log("signedTx", signedTx.toString());
        const res = await fetch(`${this.partialTxHost}/nft/submit-tx`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(signedTx.toString()),
        });
        console.log("res", res);
        return res.json();
    }
    /** Fetch token's metadata from ogmios
     * @param asset Asset in format `<currency symbol><hex(token name)>`
     * @returns Metadata of an NFT
     */
    async fetchMetadata(asset) {
        return fetch(`${this.bfUrl}/assets/${asset}`, {
            headers: { project_id: this.bfProjectId },
        })
            .then((res) => res.json())
            .then((res) => res.onchain_metadata);
    }
    /** Check if tx has appeared in block
     * @param txHash Hash of transaction to check
     * @returns Hash of transaction if it has been confirmed, `undefined` otherwise
     */
    async getTx(txHash) {
        return fetch(`${this.bfUrl}/txs/${txHash}`, {
            headers: { project_id: this.bfProjectId },
        })
            .then((res) => res.json())
            .then((res) => res.hash);
    }
    /** Withdraw part of profit from the pool of profits
     * @param param Profit to withdraw with list of NFTs to remint
     * @returns Hash of submitted transaction
     */
    async withdrawProfit(param) {
        console.log("Withdraw Profit");
        const partialTx = await this.fetchTx("contracts/withdraw-profit", JSON.stringify(param));
        let tx = this.buildTxFrom(partialTx);
        const mintNames = Object.keys(partialTx.mint).sort();
        const curr = mintNames[0].substring(0, 56);
        let metadata = {
            [curr]: {},
        };
        for (let i = 0; i < mintNames.length; i += 2) {
            const tokenMetadata = await this.fetchMetadata(mintNames[i]);
            const tn = mintNames[i + 1].substring(56);
            // @ts-ignore: TS7053
            metadata[curr][fromHex(tn)] = tokenMetadata;
        }
        console.log(metadata);
        tx = tx.attachMetadata(721, metadata);
        return this.submitTx(tx);
    }
    /** List available pools of profits
     * @param gp Request parameters
     * @returns List of profit pools
     */
    async getProfits(gp) {
        const res = await fetch(`${this.partialTxHost}/contracts/profits`, {
            method: "POST",
            headers: this.getPartialTxReqHeaders(),
            body: JSON.stringify(gp),
        }).then((res) => res.json());
        return res;
    }
    /** Get value locked in the treasury
     * @param address Address of the treasury
     * @returns List of treasury's assets
     */
    async getTreasury(address) {
        let pageResult = await fetch(`${this.bfUrl}/addresses/${address}`, {
            headers: { project_id: this.bfProjectId },
        }).then((res) => res.json());
        return pageResult.amount;
    }
    /** Cast vote on the proposal
     * NOTE: Should be used only if user don't have a voting UTXO
     * @param castVoteParams Parameters used to create a voting UTXO
     * @returns Hash of submitted transaction
     */
    async castVote(castVoteParams) {
        console.log("Cast vote");
        return this.fetchTx("contracts/cast-vote", JSON.stringify(castVoteParams)).then((tx) => this.submitTx(this.buildTxFrom(tx)));
    }
    /** Change vote, add new vote, change locked stake
     * @param modifyVoteParams Parameters used to recreate a voting UTXO
     * @returns Hash of submitted transaction
     */
    async modifyVote(modifyVoteParams) {
        console.log("Modify vote");
        return this.fetchTx("contracts/modify-vote", JSON.stringify(modifyVoteParams)).then((tx) => this.submitTx(this.buildTxFrom(tx)));
    }
    /** Unstake user's governance tokens, effectively removing all votes
     * @param vote Vote to unstake
     * @returns Hash of submitted transaction
     */
    async unstake(vote) {
        console.log("Unstake");
        return this.fetchTx("contracts/unstake", JSON.stringify(vote)).then((tx) => this.submitTx(this.buildTxFrom(tx)));
    }
    /** Get user's voting UTxO
     * @returns Voting UTxO
     */
    async getOwnVote() {
        const res = await fetch(`${this.partialTxHost}/contracts/own-vote`, {
            method: "GET",
            headers: this.getPartialTxReqHeaders(),
        }).then((res) => res.json());
        return res;
    }
    /** Get all votes
     * @returns List of voting UTxOx
     */
    async getAllVotes() {
        const res = await fetch(`${this.partialTxHost}/contracts/votes`, {
            method: "GET",
            headers: this.getDummyPartialTxReqHeaders(),
        }).then((res) => res.json());
        return res;
    }
    /** Create profit pools
     * NOTE: Development use only
     * @returns Hash of submitted transaction
     */
    async createProfit(createProfitParams) {
        console.log("Dev create profit utxos");
        return this.fetchTx("contracts/create-profit", JSON.stringify(createProfitParams)).then((tx) => this.submitTx(this.buildTxFrom(tx)));
    }
    async getProfitNfts() {
        const res = await fetch(`${this.partialTxHost}/contracts/profit-nfts`, {
            method: "GET",
            headers: this.getDummyPartialTxReqHeaders(),
        }).then((res) => res.json());
        return res;
    }
    /** Get user's NFTs and it's metadata
     * @param curr Currency symbol of NFT collection
     * @returns List of user's NFTs
     */
    async getUsersNfts(curr) {
        this.assertWalletConnected();
        const utxos = await this.lucid.wallet.getUtxos();
        let res = [];
        for (const utxo of utxos) {
            for (const [unit, amt] of Object.entries(utxo.assets)) {
                const cs = unit.substring(0, 56);
                const tn = fromHex(unit.substring(56));
                if (cs === curr.unCurrencySymbol) {
                    res.push({
                        assetClass: {
                            unAssetClass: [{ unCurrencySymbol: cs }, { unTokenName: tn }],
                        },
                        metadata: await this.fetchMetadata(unit),
                    });
                }
            }
        }
        return res;
    }
    async signUp(params) {
        await this.connectToWallet(params.wallet);
        const res = await fetch(`${this.partialTxHost}/account/create`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                walletAddress: {
                    pubKeyHash: this.connectedWallet.pkh,
                    ...(this.connectedWallet.skh
                        ? { stakeKeyHash: this.connectedWallet.skh }
                        : {}),
                },
                ...params,
            }),
        });
        const content = await res.json();
        if (!res.ok) {
            return { error: JSON.stringify(content) };
        }
        else {
            return { user: content };
        }
    }
    async getUsersByWallet(walletType) {
        await this.connectToWallet(walletType);
        const res = await fetch(`${this.partialTxHost}/account/wallet`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                pubKeyHash: this.connectedWallet.pkh,
                ...(this.connectedWallet.skh
                    ? { stakeKeyHash: this.connectedWallet.skh }
                    : {}),
            }),
        });
        const content = await res.json();
        if (!res.ok) {
            // TODO: Should we raise an exception instead? The other methods don't seem to do that.
            throw {
                code: "OtherFailure",
                msg: JSON.stringify(content),
            };
        }
        else {
            return content;
        }
    }
    async getCollections() {
        const res = await fetch(`${this.partialTxHost}/nft/get-collections`, {
            method: "GET",
        });
        const content = await res.json();
        if (!res.ok) {
            // TODO: Should we raise an exception instead? The other methods don't seem to do that.
            throw {
                code: "OtherFailure",
                msg: JSON.stringify(content),
            };
        }
        else {
            return content;
        }
    }
    async makeTestColl(cs, name, apy, price, deadline, prefixLen) {
        const res = await fetch(`${this.partialTxHost}/dev/test-col`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                devCollCurrSym: { unCurrencySymbol: cs },
                devCollName: name,
                devCollApy: apy,
                devCollPrice: price,
                devCollDeadline: deadline,
                devCollPrefixLen: prefixLen,
            }),
        });
        if (!res.ok) {
            throw {
                code: "OtherFailure",
                msg: await res.text(),
            };
        }
        return res.json();
    }
    async makeTestNFT(cs, tkName) {
        const res = await fetch(`${this.partialTxHost}/dev/test-nft`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                devNftCurrSym: { unCurrencySymbol: cs },
                devNftTokenName: { unTokenName: tkName },
            }),
        });
        if (!res.ok) {
            throw {
                code: "OtherFailure",
                msg: await res.text(),
            };
        }
        return res.json();
    }
    assertWalletConnected() {
        if (!this.isConnectedToWallet()) {
            throw {
                code: "WalletNotConnected",
                msg: "",
            };
        }
    }
    async fetchTx(endpoint, body) {
        console.log("fetchTx");
        this.assertWalletConnected();
        const res = await fetch(`${this.partialTxHost}/` + endpoint, {
            method: "POST",
            headers: this.getPartialTxReqHeaders(),
            body: body,
        });
        if (!res.ok) {
            throw {
                code: "ContractFailed",
                msg: await res.text(),
            };
        }
        const partialTx = await res.json();
        console.log(partialTx);
        return partialTx;
    }
    async submitTx(incompleteTx) {
        const tx = await incompleteTx.complete();
        console.log(tx);
        const signedTx = await tx
            .sign()
            .complete()
            .catch((e) => {
            if (e.code && e.code === 2)
                throw {
                    code: "UserCancelled",
                    msg: e.info,
                };
            throw {
                code: "OtherFailure",
                msg: JSON.stringify(e),
            };
        });
        console.log(signedTx);
        const txHash = await signedTx.submit();
        console.log(txHash);
        return txHash;
    }
}
// @ts-ignore
window.Kwarxs = Kwarxs;
