import React, { createContext, useState, useMemo, PropsWithChildren } from "react";
import { SignupParams, User, Wallet, WalletType, useSdkContext } from "./SdkContext";

interface AuthContextType {
  user: User | undefined;
  wallet: Wallet | undefined;
  isLoading: boolean;
  error: string | undefined;
  signUp: (params: SignupParams) => Promise<void>;
  connectWallet: (walletType: WalletType) => Promise<void>;
  verifyWalletConnection: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
  user: undefined,
  wallet: undefined,
  isLoading: false,
  error: undefined,
  signUp: async () => {},
  connectWallet: async () => {},
  verifyWalletConnection: async () => {},
});

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { getSdk } = useSdkContext();
  const [ user, setUser ] = useState<User | undefined>(undefined);
  const [ wallet, setWallet ] = useState<Wallet | undefined>(undefined);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ error, setError ] = useState<string | undefined>(undefined);

  const withErrorHandling = async (fn: () => Promise<void>) => {
    setIsLoading(true);
    try {
      await fn();
      setError(undefined);
    }
    catch (err: any) {
      setError(err.message);
      console.log(err);
    }
    finally {
      setIsLoading(false);
    }
  };

  const signUp = async (signUpParams: SignupParams) => {
    withErrorHandling(async () => {
      const sdk = await getSdk();
      const res = await sdk.signUp(signUpParams);

      if ("error" in res) {
        throw new Error(res.error);
      } else {
        setUser(res.user);
      }
    });
  };

  const connectWallet = async (walletType: WalletType) => {
    withErrorHandling(async () => {
      const sdk = await getSdk();

      await sdk.connectToWallet(walletType);

      const connectedWallet = sdk.connectedWallet;

      if (!connectedWallet) {
        sessionStorage.removeItem("wallet");
        setWallet(undefined);
        throw new Error("Could not connect to wallet. Please try again.");
      }

      sessionStorage.setItem("wallet", JSON.stringify(connectedWallet));
      setWallet(connectedWallet);

      const users = await sdk.getUsersByWallet(walletType);

      if (users.length === 0) {
        sessionStorage.removeItem("user");
        setUser(undefined);
        return;
      }

      // TODO: Handle multiple users with the same wallet
      const user = users[0];
      sessionStorage.setItem("user", JSON.stringify(user));
      setUser(user);
    });
  };

  /**
   * Verifies that the user/wallet in session storage is still connected.
   * If there are any inconsistencies between actual connected wallet and session data,
   * the session data is removed (auth context is also reset).
   */
  const verifyWalletConnection = async () => {
    withErrorHandling(async () => {
      try {
        const sdk = await getSdk();

        const storedWallet = sessionStorage.getItem("wallet");
        const storedUser = sessionStorage.getItem("user");

        if (!storedWallet || !storedUser) {
          throw new Error();
        }

        const wallet: Wallet = JSON.parse(storedWallet);

        await sdk.connectToWallet(wallet.walletType);

        const connectedWallet = sdk.connectedWallet;

        if (!connectedWallet) {
          throw new Error();
        }

        const user: User = JSON.parse(storedUser);
        const { walletAddress: { pubKeyHash, stakeKeyHash }} = user;

        if (pubKeyHash !== connectedWallet.pkh || stakeKeyHash !== connectedWallet.skh) {
          throw new Error();
        }

        setUser(user);
        setWallet(connectedWallet);
      } catch (error) {
        sessionStorage.removeItem("wallet");
        sessionStorage.removeItem("user");
        setWallet(undefined);
        setUser(undefined);
      }
    });
  };

  const contextValue = useMemo(() => ({
    user,
    wallet,
    isLoading,
    error,
    signUp,
    connectWallet,
    verifyWalletConnection,
  }), [ user, wallet, isLoading, error, signUp, connectWallet ]);

  return (
    <AuthContext.Provider
      value={contextValue}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => React.useContext(AuthContext);
