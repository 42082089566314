import styles from "./index.module.scss";
import { Button } from "../button";
import { NftCollection } from "../../types/nft";
import { Spinner } from "../Spinner";
import { useAuthContext } from "../../context/AuthContext";
import { useMarketplace } from "../../hooks/useMarketplace";
import { makeTransactionUrl } from "../../utils/transaction";
import { useToastMessage } from "../../hooks/useToastMessage";

type CollectionCardProps = {
  collection: NftCollection,
};

const CollectionCard = ({
  collection: {
    name,
    currencySymbol,
    total,
    remaining,
    apy,
    price,
    image,
  },
}: CollectionCardProps) => {
  const { user } = useAuthContext();
  const toast = useToastMessage();

  const {
    buyNftMutation: { mutateAsync: buyNft, isLoading: isBuyingNft },
  } = useMarketplace();

  const onBuyNft = async () => {
    try {
      if (!user) {
        throw new Error("Please connect wallet to buy an NFT.");
      }

      const buyTxHash = await buyNft({
        currencySymbol,
        walletAddress: user.walletAddress,
      });

      toast.success(
        <>
            NFT purchase{" "}
          <a href={makeTransactionUrl(buyTxHash)} target="_blank" rel="noreferrer">
              transaction
          </a>{" "}
            submitted successfully. You should see the NFT in your
            wallet shortly.
        </>
      );
    }
    catch (error: any) {
      toast.error(error);
    }
  };

  return (
    <div className={styles.nftCard__container}>
      <div className={styles.nftCard__img}>
        <img src={image} alt="nft" />
      </div>
      <h4 className={styles.nftCard__title}>
        <span className={styles.nftCard__text}>{name}</span>
      </h4>
      <table className={styles.nftCard__details}>
        <tbody>
          <tr>
            <td>Availability</td>
            <td>{remaining}</td>
          </tr>
          <tr>
            <td>Performance</td>
            <td>{apy}% APY</td>
          </tr>
          <tr>
            <td>Price</td>
            <td>{price.toString()} EUR</td>
          </tr>
        </tbody>
      </table>
      <div className={styles.nftCard__button_container}>
        <Button
          disabled={isBuyingNft}
          className={styles.nftCard__button} onClick={onBuyNft}
        >{isBuyingNft ? <Spinner width={20} /> : <>Buy Now</>}
        </Button>
      </div>
    </div>
  );
};

export default CollectionCard;
