import styles from "./index.module.scss";
import { Button } from "../button";
import { Spinner } from "../Spinner";
import { useAuthContext } from "../../context/AuthContext";
import { useMarketplace } from "../../hooks/useMarketplace";
import { makeTransactionUrl } from "../../utils/transaction";
import { useToastMessage } from "../../hooks/useToastMessage";

type NftCardProps = {
  tokenName: string;
  currencySymbol: string;
  image: string | undefined;
  collectionName: string;
  apy: number;
  price: bigint;
};

const NftCard = ({
  tokenName,
  currencySymbol,
  image,
  collectionName,
  apy,
  price,
}: NftCardProps) => {
  const { user } = useAuthContext();
  const toast = useToastMessage();

  const {
    withdrawProfitsMutation: { mutateAsync: withdrawProfits, isLoading: isWithdrawingProfits },
  } = useMarketplace();

  const onWithdrawProfits = async () => {
    try {
      if (!user) {
        // This shouldn't be possible
        throw new Error("Please connect wallet to withdraw profits.");
      }

      const withdrawTxHash = await withdrawProfits({
        currencySymbol,
        tokenName,
      });

      toast.success(
        <>
            NFT profits withdrawal{" "}
          <a href={makeTransactionUrl(withdrawTxHash)} target="_blank" rel="noreferrer">
              transaction
          </a>{" "}
            submitted successfully. You should see the profits in your
            wallet shortly.
        </>
      );
    }
    catch (error: any) {
      toast.error(error);
    }
  };

  return (
    <div className={styles.nftCard__container}>
      <div className={styles.nftCard__img}>
        <img src={image} alt="nft" />
      </div>
      <h4 className={styles.nftCard__title}>
        <span className={styles.nftCard__text}>{tokenName}</span>
      </h4>
      <table className={styles.nftCard__details}>
        <tbody>
          <tr>
            <td>Collection</td>
            <td>{collectionName}</td>
          </tr>
          <tr>
            <td>Performance</td>
            <td>{apy}% APY</td>
          </tr>
          <tr>
            <td>Price</td>
            <td>{price.toString()} EUR</td>
          </tr>
        </tbody>
      </table>
      <div className={styles.nftCard__button_container}>
        <Button
          disabled={!user || isWithdrawingProfits}
          className={styles.nftCard__button} onClick={onWithdrawProfits}
        >{isWithdrawingProfits ? <Spinner width={20} /> : <>Withdraw Profit</>}
        </Button>
      </div>
    </div>
  );
};

export default NftCard;
