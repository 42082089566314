import { Landing } from "./pages/landing";
import { createBrowserRouter } from "react-router-dom";
import MarketplacePage from "./pages/marketplace";
import YourNftsPage from "./pages/yourNfts";
export const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
    errorElement: <div>404</div>,
  },
  {
    path: "/marketplace",
    element: <MarketplacePage />,
  },
  {
    path: "/marketplace/:id",
    element: <div>NFT</div>,
  },
  {
    path: "your-nfts",
    element: <YourNftsPage />,
  },
  {
    path: "/profitpool",
    element: <div>Profit pools</div>,
  },
  {
    path: "/treasury",
    element: <div>Treasury</div>,
  },
  {
    path: "/vote",
    element: <div>Voting</div>,
  },
  {
    path: "/reward",
    element: <div>Rewards</div>,
  },
  {
    path: "/admin",
    element: <div>Admin</div>,
  },
]);
