import React, { createContext, useMemo, PropsWithChildren } from "react";
import { Kwarxs } from "kwarxs-frontend-sdk";
import { getConfig } from "../config";

export * from "kwarxs-frontend-sdk/dist/types";
export * from "kwarxs-frontend-sdk/dist/cardano-types";

interface SdkContextType {
  getSdk: () => Promise<Kwarxs>;
}

export const SdkContext = createContext<SdkContextType>({
  getSdk: async () => {
    throw new Error("SdkContext accessed outside of SdkProvider");
  },
});

/**
 * Initializes the Kwarxs SDK and provides it to the rest of the app
 */
export const SdkProvider: React.FC<PropsWithChildren> = ({ children }) => {
  // We use a ref to store the SDK promise to ensure the initialization happens only once
  const sdkPromiseRef = React.useRef<Promise<Kwarxs> | undefined>(undefined);

  const getSdk = async () => {
    if (!sdkPromiseRef.current) {
      const config = getConfig();
      sdkPromiseRef.current = Kwarxs.new(
        `https://cardano-${config.cardanoNetwork.toLocaleLowerCase()}.blockfrost.io/api/v0`,
        config.blockfrostProjectId,
        config.partialTxHost,
        config.cardanoNetwork
      );
    }

    return sdkPromiseRef.current;
  };

  const contextValue = useMemo(() => ({ getSdk }), []);

  return (
    <SdkContext.Provider
      value={contextValue}
    >
      {children}
    </SdkContext.Provider>
  );
};

export const useSdkContext = () => React.useContext(SdkContext);
