import { useMutation, useQuery } from "@tanstack/react-query";
import { Collection, useSdkContext } from "../context/SdkContext";

export type BuyNftParams = {
  currencySymbol: string;
  walletAddress: {
    pubKeyHash: string;
    stakeKeyHash: string;
  };
};

export type WithdrawProfitsParams = {
  currencySymbol: string;
  tokenName: string;
};

export const useMarketplace = () => {
  const { getSdk } = useSdkContext();

  const collectionsQuery = useQuery({
    queryKey: ["collections"],
    queryFn: () => getSdk().then((sdk) => sdk.getCollections()),
  });

  const buyNftMutation = useMutation({
    mutationFn: async ({ currencySymbol, walletAddress }: BuyNftParams) => {
      const sdk = await getSdk();
      return sdk.buyNft({
        currencySymbol: { unCurrencySymbol: currencySymbol },
        walletAddress,
      });
    },
  });

  /**
   * Fetches all NFTs in the user's connected wallet that correspond to currency symbols
   * of collections in the marketplace. Collection info is added to each NFT for convenience.
   */
  const userNftsQuery = useQuery({
    queryKey: ["userNfts"],
    queryFn: () => {
      const collections = collectionsQuery.data;
      const currencySymbols = collections?.map((collection) => collection.currencySymbol);
      const promises = currencySymbols?.map((currencySymbol) => {
        return getSdk().then((sdk) => sdk.getUsersNfts({ unCurrencySymbol: currencySymbol }));
      });

      return Promise.all(promises || []).then((results) => {
        const nfts = results.flat();
        const collectionsMap = collections?.reduce((acc, collection) => {
          return { ...acc, [collection.currencySymbol]: collection };
        }, {} as Record<string, Collection>);

        return nfts.map((nft) => {
          const collection = collectionsMap?.[nft.assetClass.unAssetClass[0].unCurrencySymbol];
          return {
            ...nft,
            collection,
          };
        });
      });
    },
    enabled: !!collectionsQuery.data,
  });

  const withdrawProfitsMutation = useMutation({
    mutationFn: async ({ currencySymbol, tokenName }: WithdrawProfitsParams) => {
      const sdk = await getSdk();
      const profitNfts = await sdk.getProfitNfts();

      const matchingPoolNfts = profitNfts.filter((nft) => nft.profitPoolNftCollection.unCurrencySymbol === currencySymbol);

      if (!matchingPoolNfts || matchingPoolNfts.length === 0) {
        throw new Error("Problem withdrawing profits. No profit pool NFT found.");
      }

      // This assumes there is only a single profit pool NFT per collection
      const poolNft = matchingPoolNfts[0];

      // Get the profit pools matching the profit NFT
      const profitPools = await sdk.getProfits({
        gpPoolNft: {
          unAssetClass: [
            poolNft.profitPoolNftSymbol,
            poolNft.profitPoolNftTokenName,
          ],
        },
      });

      // Select random profit pool to avoid congestion
      const pool = profitPools[Math.floor(Math.random() * profitPools.length)];

      return sdk.withdrawProfit({
        wpPool: pool,
        wpNfts: [{ unTokenName: tokenName }],
        wpCollection: { unCurrencySymbol: currencySymbol }
      });
    },
  });

  return {
    collectionsQuery,
    buyNftMutation,
    userNftsQuery,
    withdrawProfitsMutation,
  };
};

