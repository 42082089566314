import mask from "../../assets/img/pink.gif";
import CollectionCard from "../../components/CollectionCard";
import styles from "./index.module.scss";
import nft1 from "../../assets/img/nft-image1.png";
import { useMarketplace } from "../../hooks/useMarketplace";
import { Spinner } from "../../components/Spinner";

const marketText =
  "31600 unique, hand-crafted artworks comprise this Sun Gowds NFT series. Embedded token" +
  " metadata provides fractionalized ownership of a profitable grid-connected solar farm in Germany." +
  " Note that 80% of every sale goes directly into the Sun Gowds DAO treasury.";

const Marketplace = () => {
  const {
    collectionsQuery: { data: collections, isLoading },
  } = useMarketplace();

  const renderCollections = () => {
    const now = Date.now();
    return collections?.filter((col) => col.remaining > 0 && col.deadline > now).map((col) => {
      return (
        <CollectionCard
          key={`${col.name}${col.currencySymbol}`}
          collection={{
            currencySymbol: col.currencySymbol,
            image: nft1,
            name: col.name,
            remaining: col.remaining,
            apy: col.apy,
            price: col.price,
            total: col.total,
          }}
        />
      );
    });
  };

  return (
    <div className={styles.marketplace__wrapper}>
      <div className={styles.marketplace__container}>
        <div className={styles.marketplace__heading}>
          <div className={styles.marketplace__img}>
            <img src={mask} alt="pink mask" />
          </div>
          <div>
            <div>
              <h3 className={styles.marketplace__title}>Sun Gwods</h3>
              <h4 className={styles.marketplace__subtitle}>By: Kwarxs</h4>
            </div>
            <div>
              <p className={styles.marketplace__text}>{marketText}</p>
            </div>
          </div>
        </div>
        <div className={styles.marketplace__mobile__text}>
          <p>
            <b>Released Feb 14, 2023</b>
          </p>
          <p>{marketText}</p>
        </div>
      </div>
      <div className={styles.nftcard__container}>
        {isLoading
          ? <Spinner color="grey" />
          : <div className={styles.marketplace__nftcard}>{renderCollections()}</div>
        }
      </div>
    </div>
  );
};

export default Marketplace;
